footer {
  background-color: #2b354b;
  padding: 2rem 0;
  color: #fff;

  border-bottom: 5px solid $color-blue;

  h4 {
    font-weight: 100;
    margin: 0;
    padding: 0;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;

    &:after, &:before {
      content: ' ';

      position: absolute;
      bottom: -2px;
      left: 0;

      height: 2px;
      width: 100%;

      background-color: rgba(193, 212, 255, 0.3)
    }

    &:after {
      background-color: $color-blue;
      width: 7%;
    }

    &:before {
      width: 91%;
      left: 9%;
    }
  }

  .footer-service {
    ul {

      margin: 0;
      padding: 0;
      list-style: none;

      li {
        a {

          display: block;
          width: 100%;

          padding: 0.5rem 0;
          font-weight: 100;

          border-bottom: 1px solid rgba(193, 212, 255, 0.3);

          color: #fff;

          &:hover {
            color: $color-blue;
          }
        }
      }
    }
  }

  .footer-brand {
    @extend .main-logo;

    @media (max-width: 768px){
      text-align: center;
    }

    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
    }
  }

  .footer-openhours {
    @extend .openhours-list-include;

    ul {
      font-size: 13px;

      &.time-list {
        li {
          padding: 0.67rem 0 !important;

          &.time-title {

            padding: 0 !important;

            h4 {
              text-align: left !important;
              font-weight: 100;

              padding-bottom: 0.5rem !important;
              margin-bottom: 0.5rem !important;
            }
          }

          &:not(:last-child):not(.time-title) {
            &:after {
              left: 0 !important;
              width: 100% !important;
              background-color: rgba(193, 212, 255, 0.3) !important;
            }
          }

          &.active {
            color: $color-blue !important;
          }
        }
      }
    }
  }
}