.subnavbar {
  background-color: #f8f8f8;

  padding: 1.2rem 0 0 0;

  .sub-info {
    position: relative;

    //&:not(:first-child) {
    //  border-left: 1px solid #e8e8e8;
    //}

    @media (max-width: 991px) {
      margin-bottom: 0.5rem;
    }

    .sub-info-icon {

      position: absolute;
      height: 100%;
      font-size: 2.2rem;

      color: $color-blue;
      text-align: center;

      width: 50px;
      margin-top: -2px;

      background-color: #fff;
      padding: 0.1rem;
      border-radius: 0.2rem;
      line-height: 2.6rem;
      border: 1px solid #e8e8e8;

    }
    .sub-info-text {
      display: inline-table;
      height: 100%;
      padding-left: 60px;

      span {
        &:last-child {
          display: block;
          color: #777777;
          font-weight: 100;
        }
      }
    }
  }

  .subnavbar-alert {
    background-color: $color-red;
    color: #fff;
    padding: 1.2rem 0;

    margin-top: 1rem;

    text-align: center;

    span {
      font-size: 120%;

      &:after {
        content: "\A";
        white-space: pre;
      }
    }
  }
}