nav {
  &.navbar {

    background-color: #fff;
    border-top: 5px solid $color-blue;
    padding: 1rem 1rem;


    @extend .main-logo;

    @keyframes beat{
      0% { -webkit-transform: scale(1); }
      8% { -webkit-transform: scale(0.95);  }
      17% { -webkit-transform: scale(1.05); }
      25% { -webkit-transform: scale(0.95); }
      87.5% { -webkit-transform: scale(0.95); }
      100%  { -webkit-transform: scale(1); }
    }

    img {
      animation: beat 2s infinite;
    }

    .navbar-nav {
      margin-left: auto !important;
      margin-right: 0 !important;
    }
  }
}

.main-logo {
  .navbar-brand {

    padding-top: .55rem;
    padding-bottom: .55rem;

    margin-right: 2rem;

    img {
      margin-right: 0.5rem;
    }

    >.brand-name {
      display: inline-block;
      margin-top: 2px;

      span {
        display: block;
      }

      .brand-title {
        color: $color-blue;

        font-size: 1.49rem;
        font-weight: 300;
      }

      .brand-name {
        color: $color-red;

        font-weight: 600;
        font-size: 1.7rem;

        margin-top: -11px;
      }

      .brand-subtitle {
        color: $color-blue;
        font-size: 1.16rem;

        text-align: right;
        margin-top: -8px;
        margin-right: -3px;
        font-weight: 300;
      }
    }
  }
}