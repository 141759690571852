section {
  &.section-contact-info {

    margin-bottom: 2.5rem;

    h1, p {
      text-align: center;
    }

    .infos {

      margin-top: 2.5rem;

      .info {
        position: relative;
        margin-bottom: 3rem;

        .info-icon {

          position: absolute;
          height: 100%;
          font-size: 2.2rem;

          color: $color-blue;
          text-align: center;

          width: 50px;
          margin-top: -2px;

          background-color: #fff;
          padding: 0.1rem;
          border-radius: 0.2rem;
          line-height: 2.6rem;
          border: 1px solid #e8e8e8;

        }
        .info-text {
          display: inline-table;
          height: 100%;
          padding-left: 60px;

          span {
            &:last-child {
              display: block;
              color: #777777;
              font-weight: 100;
            }
          }
        }
      }
    }

    h2 {
      text-align: center;
      position: relative;
      z-index: 10;
      text-shadow: stroke(8, #fff);

      margin-top: 0.5rem;
      margin-bottom: 1rem;

      &:before {

        content: ' ';

        width: 80%;
        height: 7px;

        position: absolute;
        top: 17px;
        z-index: -3;
        left: 10%;

        background-color: $color-blue;

        border-left: 5px #fff solid;
        border-right: 5px #fff solid;
      }

      &:after {

        content: ' ';

        width: 100%;
        height: 3px;

        position: absolute;
        top: 19px;
        z-index: -4;
        left: 0;

        background-color: #2d4b5a;
      }
    }

  }
}