section {
  &.section-aboutme-title {

    background-color: $color-blue;
    color: #fff;

    margin-bottom: 3rem;

    padding: 2rem 0;

    h1 {
      margin: 0;
      padding: 0;

      font-weight: 300;

      font-size: 20px;
    }

    .breadcrumb {
      &.section-breadcrumb {
        margin: 0;
        padding: 0;
        color: #fff;

        .breadcrumb-item {
          color: rgba(255, 255, 255, 0.7);

          &.active {
            color: #fff;
          }

          &+ .breadcrumb-item::before {
            color: rgba(255, 255, 255, 0.7);
          }

          a {
            color: rgba(255, 255, 255, 0.7);
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }
}