section {
  &.section-openhours {
    padding: 4.5rem 0;

    background-color: #f8f8f8;

    .openhours-me {
      padding: 0 1rem;

      .me-text {

        background-color: #fff;
        padding: 1rem 1.5rem;

        p {
          color: #777777;
        }

      }
    }
    .openhours-time {

      background-image: url('/images/home/openhours.jpg');
      background-size: cover;
      background-position: center;
      position: relative;

      color: #fff;

      &:before {
        content: ' ';
        background-color: $color-blue;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.9;

      }
      @extend .openhours-list-include;

    }
  }
}

.openhours-list-include {

  ul {
    &.time-list {
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      z-index: 10;

      li {
        padding: 1rem 1.5rem;
        position: relative;

        font-weight: 100;

        &:not(:last-child):not(.time-title) {
          &:after {
            position: absolute;
            bottom: 0;
            left: 10%;

            height: 1px;
            width: 80%;
            //background-color: rgba(43, 53, 75, 0.2);
            background-color: rgba(193, 212, 255, 0.8);

            content: ' ';
          }
        }

        &:hover {
          &:not(.time-title) {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }

        &.time-title {
          h4 {
            margin: 0;
            padding: 0;
            text-align: center;
            width: 100%;
          }
        }

        > div {
          width: 50%;
          display: inline-block;
        }

        .time {
          float: right;
          text-align: left;
        }

        .day {
          font-weight: 400;
        }

        &.disable {
          color: rgba(255, 255, 255, 0.5);
        }

        &.active {
          color: #2b354b;

          .day {
            font-weight: 600;
          }

          .time {
            font-weight: 400;
          }
        }
      }
    }
  }
}