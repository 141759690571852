.carousel {
  &.header-carousel {

    height: 600px;

    .carousel-inner {
      height: inherit;

      .carousel-item {
        height: inherit;

        .carousel-caption {
          text-align: left;
          padding-bottom: 130px;

          h3, p {
            max-width: 550px;
          }

          h3 {
            text-transform: uppercase;
            font-size: 44px;

          }

          p {
            font-size: 19px;
            font-weight: 100;
          }
        }
      }
    }

    .img-fluid {
      width: auto;
      height: 100%;

      max-width: none;

      position: absolute;
      right: 0px;
      
      @media (min-width: 1625px) {
        width: 100%;
        height: auto;
      }
    }

  }
}