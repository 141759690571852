section {
  &.section-drive-list {

    background-image: url('/images/home/road.jpg');
    background-size: cover;
    background-position: center;
    position: relative;

    padding: 8rem 1rem;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      width: 100%;
      height: 100%;

      content: ' ';

      background-color: $color-blue;

      opacity: 0.8;

      z-index: 1;
    }

    .item, .map {
      position: relative;
      z-index: 5;

    }

    .item {

      text-align: center;
      margin-bottom: 3rem;

      background-color: #fff;
      border-radius: 1rem;
      padding: 1rem;


      .icon {

        font-size: 40px;
        color: $color-blue;
        margin-bottom: 0.5rem;

      }

      h2 {
        margin-bottom: 0.7rem;
        color: $color-blue;
      }

      p {
        color: #a0a0a0;
        font-size: 13px;
      }
    }

    .map {
      text-align: center;
      .btn {
        display: inline-block;
        font-size: 20px;
        border-color: transparent;
        white-space: normal;
        background-color: #fff;

        &:hover {
          color: $color-red;
        }
      }
    }

  }
}