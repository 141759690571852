section {
  &.section-aboutme-me {
    margin-bottom: 3rem;

    h2 {
      small {
        text-transform: uppercase;
        color: $color-blue;
        font-size: 17px;
        display: block;
        padding-bottom: 0.75rem;
      }

      font-size: 50px;
      font-weight: 300;

      margin-bottom: 1.5rem;
      padding-bottom: 1rem;
      position: relative;

      &:after, &:before {
        content: ' ';
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: rgba(193, 212, 255, 0.3);

        transition: all 1s;
      }

      &:before {
        width: 83%;
        left: 17%;
      }

      &:after {
        background-color: $color-blue;
        width: 15%;
      }
    }

    p {
      font-size: 19px;
      color: #9c9c9c;
      font-weight: 200;
    }
  }
}