body {
  color: $text-color;
  font-family: $font-family-sans-serif;
  font-weight: 300;
}

a {
  color: $text-color;
  transition: all 0.5s;
  font-weight: 400;

  &:hover {
    color: $color-blue;
    text-decoration: none;
  }
}

.breadcrumb {
  background: transparent;
  padding-left: 0;
  text-align: left;

  .breadcrumb-item {

    color: $text-color;
    font-weight: 400;
    float: none;
    display: inline-block;

    a {
      font-weight: 300;
      color: $color-blue;
    }
  }
}