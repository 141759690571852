@import "emergency";

section {
  &.section-service-list {

    margin: 3rem 0;

    .list-inline-item {

      margin-right: 10px;
      padding: 0.25rem;
      background-color: rgba(100, 168, 202, 0.2);
      font-size: 0.9rem;
      border-radius: 0.5rem;
      margin-bottom: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .list-box {
      width: 100%;
      height: 100%;
      position: relative;

      border-radius: 0.25rem 0.25rem 0 0;
      overflow: hidden;

      margin-bottom: .5rem;

      @media (max-width: 768px) {
        margin-bottom: 2.5rem;
      }

      &:hover {
        .box-image {
          filter: grayscale(0%);
          transform: scale(1);
        }

        .box-title {
          &:before {
            width: 80%;
            left: 20%;
          }

          &:after {
            background-color: $color-blue;
            width: 18%;
          }
        }
      }

      .box-image-link {
        overflow: hidden;
        display: block;
      }

      .box-image {
        width: 100%;
        height: auto;
        display: block;

        transition: all 0.5s, transform 1s ;
        transform: scale(1.07);

        filter: grayscale(50%);

      }

      .box-title, .box-info, .box-link {
        padding: 0 0.7rem;
      }

      .box-link {
        display: inline-block;
        &::first-letter {
          color: $color-blue;
          font-weight: 400;
        }
      }

      .box-info {
        color: #777777;
      }

      .box-title {

        margin-top: 10px;
        padding-top: 10px;
        position: relative;

        font-size: 22px;
        font-weight: 400;

        &:after, &:before {
          content: ' ';
          position: absolute;
          top: -2px;
          left: 0;
          height: 2px;
          width: 100%;
          background-color: rgba(193, 212, 255, 0.3);

          transition: all 1s;
        }

        &:before {
          width: 83%;
          left: 17%;
        }

        &:after {
          background-color: $color-blue;
          width: 15%;
        }
      }
    }

    .img-footer {

      width: 100%;
      height: 100px;
      margin: auto;
      margin-top: 1rem;
      margin-bottom: 1rem;

      &.apple {
        background: url("/images/service/title_3.jpg");
        background-position: center;
        background-size: cover;
      }
    }

  }
}