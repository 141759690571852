section {
  &.section-service-emergency {

    margin: 3rem 0;
    .emergency-contact {
      .contact-info {
        margin-bottom: 1rem;
        position: relative;

        .contact-icon {

          position: absolute;
          height: 100%;
          font-size: 2.2rem;

          color: $color-blue;
          text-align: center;

          width: 50px;
          margin-top: -2px;

          background-color: #fff;
          padding: 0.1rem;
          border-radius: 0.2rem;
          line-height: 2.6rem;
          border: 1px solid #e8e8e8;

        }
        .contact-text {
          display: inline-table;
          height: 100%;
          padding-left: 60px;

          span {
            &:last-child {
              display: block;
              color: #ccc;
              font-weight: 100;
            }
          }
        }
      }
    }

    #praxisMap {

      min-height: 330px;

    }
  }
}