section {
  .section-title {

    position: relative;
    padding-bottom: 0.5rem;
    margin-bottom: 2.5rem;

    &:after, &:before {
      content: ' ';
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: rgba(193, 212, 255, 0.3);

      transition: all 1s;
    }

    &:before {
      width: 94%;
      left: 6%;
    }

    &:after {
      background-color: $color-blue;
      width: 5%;
    }

    &.right {
      text-align: right;

      &:before {
        width: 94%;
        right: 6%;
      }

      &:after {
        background-color: $color-blue;
        width: 5%;
        right: 0;
        left: auto;
      }
    }
  }

  .breadcrumb {
    &.section-breadcrumb {
      margin-top: -2.8rem;
      text-align: right;
    }
  }
}