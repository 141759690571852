section {
  &.section-keywords {

    padding: 4.5rem 0;

    .keyword {
      text-align: center;
      .keyword-icon {

        font-size: 3rem;
        color: $color-blue;

        padding-bottom: 1.2rem;
        margin-bottom: 1.2rem;
        position: relative;

        &:after {
          position: absolute;
          left: 30%;
          bottom: 0;
          width: 40%;
          height: 1px;
          background-color: $color-blue;
          content: ' ';
        }

      }

      .keyword-title {
        //color: $color-blue;
        font-weight: 600;
        font-size: 23px;
        padding-bottom: 0.5rem;
      }

      .keyword-text {
        font-size: 13px;
        color: #777777;
      }
    }

  }
}