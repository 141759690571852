section {
  &.section-aboutme-timeline {

    h2 {

      font-size: 30px;
      font-weight: 300;

      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      position: relative;

      &:after, &:before {
        content: ' ';
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: rgba(193, 212, 255, 0.5);

        transition: all 1s;
      }

      &:before {
        width: 72%;
        left: 28%;
      }

      &:after {
        background-color: $color-blue;
        width: 26%;
      }
    }

    .item {
      width: 100%;
      position: relative;
      display: table;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      min-height: 55px;
      box-sizing: content-box;

      .date {
        position: absolute;
        color: $color-blue;
        font-size: 50px;
        font-weight: 500;
        margin: 0;
        width: 8rem;

        font-family: 'Roboto Mono', monospace;

      }

      .info {
        padding-left: 9.3rem;

        font-size: 19px;
        color: #9c9c9c;
        font-weight: 200;
        min-height: 55px;

        vertical-align: middle;
        display: table-cell;

        &::first-line {
          font-weight: 300;
        }
      }

      &:after, &:before {
        content: ' ';
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: rgba(193, 212, 255, 0.5);

        transition: all 1s;
      }

      &:before {
        width: 72%;
        left: 28%;
      }

      &:after {
        background-color: $color-blue;
        width: 26%;
      }


    }
  }
}