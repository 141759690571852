section {
  &.section-book {


    background-image: url('/images/home/book.jpg');
    background-size: cover;
    background-position: center;

    padding: 2.5rem 0;
    position: relative;

    color: #fff;

    &:before {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: $color-red;
      opacity: 0.5;

      content: ' ';
    }

    .book-text {
      font-size: 28px;
      line-height: 50px;
      text-transform: uppercase;

      text-align: center;

      span {
        font-weight: 600;
      }
    }

    .book-button {
      .btn-book {
        width: 100%;
        height: 100%;
        color: #fff;
        border: 1px solid #fff;
        font-size: 19px;
        line-height: 33px;

        &:hover {
          background-color: #fff;
          color: $color-red;
        }

      }
    }
  }
}